export default class StatePersister {
  constructor(storage) {
    if (this.constructor === StatePersister) {
      throw new TypeError('Abstract class "StatePersister" cannot be instantiated directly.');
    }
    this.storage = storage;
    this.namespace = 'default';
    this.enabled = true;
  }
  setNameSpace(namespace) {
    this.namespace = namespace;
  }
  enable(enable) {
    this.enabled = enable;
  }
  buildKey(subject) {
    return `${this.namespace}_${subject}`;
  }
  getPersistedValue(key, fallbackValue) {
    if (!this.enabled) {
      return fallbackValue;
    }

    const builtKey = this.buildKey(key);

    const persistedObject = this.storage.getItem(builtKey) && JSON.parse(this.storage.getItem(builtKey));
    return persistedObject ? persistedObject.value : fallbackValue;
  }
  persistValue(key, value) {
    if (!this.enabled) {
      return;
    }
    const persistObject = { value };
    this.storage.setItem(this.buildKey(key), JSON.stringify(persistObject));
  }
  getCleanupFunc(keys) {
    const builtKeys = keys.map(key => this.buildKey(key));
    const storage = this.storage;
    return function() {
      builtKeys.forEach(builtKey => {
        storage.removeItem(builtKey);
      });
    };
  }
}
