<template>
  <div>
    <ul v-show="visible" ref="menu" class="context-menu">
      <li v-for="(option, index) in options" :key="index" class="context-menu__item" @click="optionClicked(option)">
        {{ option.name }}
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'ContextMenu',
  props: {
    options: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      item: null,
      menuWidth: null,
      menuHeight: null,
      visible: false
    };
  },
  watch: {
    visible(value) {
      if (value) {
        document.body.addEventListener('click', this.hideContextMenu);
      } else {
        document.body.removeEventListener('click', this.hideContextMenu);
      }
    }
  },
  methods: {
    showMenu(el, event, item) {
      const offsetX = el.getBoundingClientRect().left;
      const offsetY = el.getBoundingClientRect().top;
      this.item = item;
      const menu = this.$refs.menu;
      if (!menu) {
        return;
      }
      if (!this.menuWidth || !this.menuHeight) {
        menu.style.visibility = 'hidden';
        menu.style.display = 'block';
        this.menuWidth = menu.offsetWidth;
        this.menuHeight = menu.offsetHeight;
        menu.removeAttribute('style');
      }
      if (this.menuWidth - offsetX + event.pageX >= window.innerWidth) {
        menu.style.left = event.pageX - offsetX - this.menuWidth + 2 + 'px';
      } else {
        menu.style.left = event.pageX - offsetX - 2 + 'px';
      }
      if (this.menuHeight + event.pageY - offsetY >= window.innerHeight) {
        menu.style.top = event.pageY - offsetY - this.menuHeight + 2 + 'px';
      } else {
        menu.style.top = event.pageY - offsetY - 2 + 'px';
      }
      this.visible = true;
    },
    hideContextMenu() {
      this.visible = false;
    },
    optionClicked(option) {
      this.hideContextMenu();
      this.$emit('option-clicked', {
        item: this.item,
        option: option
      });
    }
  }
};
</script>

<style lang="scss">
$light-grey: #ecf0f1;
$grey: darken($light-grey, 15%);
$blue: #007aff;
$white: #fff;
$black: #333;
.context-menu {
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  display: none;
  list-style: none;
  position: absolute;
  z-index: 1000000;
  background-color: $light-grey;
  border-bottom-width: 0px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  box-shadow: 0 3px 6px 0 rgba($black, 0.2);
  border-radius: 4px;
  display: block;
  &__item {
    display: flex;
    color: $black;
    cursor: pointer;
    padding: 5px 15px;
    align-items: center;
    &:hover {
      background-color: $blue;
      color: $white;
    }
  }
  li {
    &:first-of-type {
      margin-top: 4px;
    }
    &:last-of-type {
      margin-bottom: 4px;
    }
  }
}
.el-tabs {
  .el-tabs__content {
    overflow: visible;
  }
}
</style>
